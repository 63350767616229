/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';
import { useTranslation } from 'react-i18next';

import LoginPage from './pages/AuthPage';
import AppRoute from 'utils/AppRoute';
import PrivateRoute from './PrivateRoute';
import MainLayout from './MainLayout';
import NotFoundPage from './pages/ErrorPage/NotFoundPage';
import { HomePage } from './pages/HomePage/Loadable';
import routes from './routeData';
import DashboardPage from './pages/DashboardPage';

export function App() {
  const { i18n } = useTranslation();

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - ប្រព័ន្ធគ្រប់គ្រងឯកសារ "
        defaultTitle="ប្រព័ន្ធគ្រប់គ្រងឯកសារ "
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="ប្រព័ន្ធគ្រប់គ្រងឯកសារ " />
      </Helmet>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        {/* <AppContent /> */}
        <Route
          path={AppRoute.baseUrl}
          element={
            <PrivateRoute>
              <MainLayout />
            </PrivateRoute>
          }
        >
          {routes.map((route: any) => (
            <Route
              key={route.id}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Route>

        {/* <Redirect exact from="/" to={AppRoute.baseUrl} /> */}

        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      <GlobalStyle />
    </BrowserRouter>
  );
}
