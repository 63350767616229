import React from 'react';
import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';

const { Text } = Typography;
const columns: ColumnsType<any> = [
  {
    title: 'ល.រ',
    dataIndex: 'id',
    align: 'center',
    width: '36px',
    render: (e, record, index) => <Text>{index + 1}</Text>,
  },
  {
    title: 'ឈ្មោះ',
    dataIndex: 'id',
    align: 'center',
    render: (e, record) => (
      <Text style={{ textAlign: 'left', display: 'block' }}>
        {record?.fn || ''} {record?.ln || ''}
      </Text>
    ),
  },
  {
    title: 'កំពុងដំណើរការ',
    dataIndex: 'id',
    align: 'center',
    render: (e, record) => <Text>{record?.totalInProgress || 0}</Text>,
  },
  {
    title: 'របាយការណ៍រួច',
    dataIndex: 'id',
    align: 'center',
    render: (e, record) => <Text>{record?.totalFinish || 0}</Text>,
  },
  {
    title: 'ករណីសរុបរួម',
    dataIndex: 'id',
    align: 'center',
    render: (e, record) => <Text>{record?.total || 0}</Text>,
  },
];

function DocByUserReport({ data }) {
  return (
    <Table
      size="small"
      rowKey="id"
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
      summary={pageData => {
        let summary = {
          inProgress: 0,
          finish: 0,
          totalDocSign: 0,
          totalDocUnsign: 0,
        };

        pageData.forEach(
          ({ totalInProgress, totalFinish, totalDocSign, totalDocUnsign }) => {
            summary.inProgress += totalInProgress || 0;
            summary.finish += totalFinish || 0;
            summary.totalDocSign += totalDocSign || 0;
            summary.totalDocUnsign += totalDocUnsign || 0;
          },
        );

        return (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={2} align="center" index={1}>
                <Text strong>សរុប</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={2}
                align="center"
                className="bg-light-yellow"
              >
                <Text>{summary.inProgress}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={3}
                align="center"
                className="bg-light-yellow"
              >
                <Text>{summary.finish}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={4}
                align="center"
                className="bg-light-red"
              >
                <Text>{summary.inProgress + summary.finish}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={3} align="center" index={1}>
                មេចាររបាយការណ៍រួច
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={2}
                align="center"
                className="bg-light-blue"
              >
                <Text>{summary.totalDocSign}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={3} align="center" index={1}>
                សល់នៅមេខេត្ត
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={2}
                align="center"
                className="bg-light-orange"
              >
                <Text>{summary.totalDocUnsign}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        );
      }}
    />
  );
}

export default DocByUserReport;
