import { useEffect } from 'react';
import { Modal } from 'antd';
import { AppStorage } from 'utils';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCommon } from './slice/selectors';
import { useCommonSlice } from './slice';
import AppRoute from 'utils/AppRoute';

export function MyWrapper({ children }) {
  useCommonSlice();
  const navigate = useNavigate();
  const { status } = useSelector(selectCommon);

  useEffect(() => {
    if (status === 401 || status === 403) {
      const errorModal = Modal.error({});

      if (status === 401) {
        errorModal.update({
          title: 'មិនមានសិទ្ធប្រើប្រាស់',
          content: (
            <p>
              មិនមានសិទ្ធគ្រប់គ្រាន់សម្រាប់ចូលប្រើប្រាស់ទំព័រនេះទេ។ <br />
              សូម​ធ្វើការ​​ចូលក្នុងប្រព័ន្ធសារឡើងវិញ​
              ដើម្បី​បន្ត​ប្រើប្រាស់​ប្រព័ន្ធ។
            </p>
          ),
          onOk() {
            AppStorage.removeAuthData(true);
          },
        });
      } else if (status === 403) {
        errorModal.update({
          title: 'Forbidden',
          content: (
            <div>
              <p>You don't have permission to access this page.</p>
            </div>
          ),
          onOk() {
            navigate(AppRoute.dashboard, {
              replace: true,
            });
          },
        });
      }
    }

    return () => {
      Modal.destroyAll();
    };
  }, [status]);

  return children;
}
